import React from 'react'
import Layout from '../Layout'

import LargeFooterSection from '../../sections/LargeFooterSection'
import LocationSection from '../LocationSection'
import { NavArea, NavLayout } from '../Navigation'
import PlainSection from '../PlainSection'

const IndexPage = () => (
  <Layout>
    <NavLayout>
      <NavArea dark>
        <PlainSection>
          <iframe
            className='airtable-embed'
            src='https://airtable.com/embed/shrQp5RAoZu4CF6Yg?backgroundColor=green'
            frameBorder='0'
            width='100%'
            height='1000px'
          />
        </PlainSection>
        <span>
          <LocationSection />
          <LargeFooterSection />
        </span>
      </NavArea>
    </NavLayout>
  </Layout>
)

export default IndexPage
